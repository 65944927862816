<template>
    <div class="events">
        <div class="events-content">
            <div class="events-box">

                <div class="event-list">
                    <div v-for="event in events" :key="event.id" class="item ev-block ">
                        <router-link :to="`/events/${event.id}`">
                            <div class="item ev-image">
                                <DynamicImage :imageUrl="getImgUrl(event.image)" :imageStyle="eventImgStyle" />
                            </div>
                            <div class="item ev-title" v-html="event.title"></div>
                        </router-link>
                        <div class="item-date">
                            <img class="calendar" src="@/assets/calendar.svg" />
                            <div class="item1 ev-date" v-html="formatDate(event.date_of_event)"></div>
                        </div>
                        <div class="item-location">
                            <img class="location" src="@/assets/location-pin.svg" />
                            <div class="item1 ev-location" v-html="event.location"></div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- <MyButton /> -->
        </div>
    </div>
</template>

<script>
import DynamicImage from '@/components/utility/DynamicImage.vue';

export default {
    name: 'EventsBlock',
    components: {
        DynamicImage,
    },
    data() {
        return {
            events: [],
            url: '',
            eventImgStyle: {
            }
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        formatDate(dateString) {
            const options = { day: 'numeric', month: 'long', year: 'numeric' };
            const date = new Date(dateString);
            return date.toLocaleDateString('hr-HR', options);
        },

        async fetchData() {
            try {
                // const currentDate = new Date().toISOString();
                const currentDate = '2024-04-03T19:56:25.516Z';

                const query = `?sort=date_of_event&filter[date_of_end_of_event][_gte]=${currentDate}`;
                const response = await this.$directusService.fetchEvents(query);

                if (response && response.data) {
                    this.events = response.data.slice(0, 5);
                }

                this.url = this.$directusService.fetchUrl();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        getImgUrl(imageId) {
            return `${this.url}/assets/${imageId}`;
        },
    }
}
</script>

<style>
a {
    text-decoration: none;
    color: #FFFFFF;
}

a :hover {
    color: #DDDDDD;
}

.events {
    font-family: 'Roboto';
    font-style: normal;
    text-align: left;
    color: #FFFFFF;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: 15px;
}

.events-content {
    width: 100%;
    max-width: 1400px;
}

.events-box {
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
    padding: 0px 0px 10px;
    gap: 10px;
    text-align: left;
}

.event-list {
    display: flex;
    width: 100%;
    /* overflow: hidden; */
    gap: 10px;
    padding: 10px 0px 0px;
}

.item {
    width: 100%;
}

.ev-image {
    height: 170px;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.5);
}

.ev-title {
    font-weight: 500;
    font-size: 18px;
    padding: 10px 0px 5px 0px;
    /*  */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ev-date {
    font-weight: 300;
    font-size: 15px;
}

.ev-location {
    font-weight: 300;
    font-size: 15px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.item-date {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.item-location {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding-block: 5px;
}

.calendar {
    height: 19px;
}

.location {
    height: 19px;
}
</style>