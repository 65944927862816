<template>
  <div class="hp-container">
    <div class="hp-page">
      <div class="hp-content">
        <HeroBlock />
        <SponsorsBlock />
        <NewsBlock />
        <EventsBlock />
        <StatisticsBlock />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HeroBlock from "@/components/homeBlocks/HeroBlock.vue";
import SponsorsBlock from "@/components/homeBlocks/SponsorsBlock.vue";
import NewsBlock from "@/components/homeBlocks/NewsBlock.vue";
import StatisticsBlock from "@/components/homeBlocks/StatisticsBlock.vue";
import EventsBlock from "@/components/homeBlocks/EventsBlock.vue";

@Options({
  components: {
    HeroBlock,
    SponsorsBlock,
    NewsBlock,
    StatisticsBlock,
    EventsBlock,
  },
})
export default class HomeView extends Vue {
  handleEvent() {
    this.$router.push("/statut");
  }
}
</script>

<style>
/* Place in your CSS file at the top of the document */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@100&display=swap');

.hp-content {
  display: flex;
  flex-direction: column;
  padding-block: 30px;
  gap: 30px;
}
</style>
