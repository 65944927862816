import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomePageView.vue";
import keycloakService from "@/utility/auth/KeycloakService";
import NewsArticle from "@/components/displayedItems/NewsArticle.vue";
import EventArticle from "@/components/displayedItems/EventArticle.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about-mensa",
    name: "aboutmensa",
    component: () =>
      import("../views/Navigator/Mensa/AboutMensa/AboutMensaView.vue"),
  },

  {
    path: "/testing",
    name: "testing",
    component: () =>
      import("@/views/Navigator/TestingView.vue"),
  },

  {
    path: "/projects",
    name: "projects",
    component: () =>
      import("@/views/Navigator/Projects/ProjectsView.vue"),
  },

  {
    path: "/contact",
    name: "contact",
    component: () =>
      import("@/views/Navigator/ContactView.vue"),
  },


  {
    path: "/webshop",
    name: "webshop",
    component: () =>
      import("@/views/Navigator/WebshopView.vue"),
  },

  {
    path: "/profile",
    name: "profile",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import("@/views/Navigator/Private/ProfileView.vue"),
  },

  {
    path: "/statute",
    name: "statute",
    component: () =>
      import("@/views/Navigator/Mensa/StatuteView.vue"),
  },
  {
    path: "/officials",
    name: "officials",
    component: () =>
      import("@/views/Navigator/Mensa/OfficialsView.vue"),
  },
  {
    path: "/mensa-intl",
    name: "mensaintl",
    component: () =>
      import("@/views/Navigator/Mensa/MensaInternationalView.vue"),
  },
  {
    path: '/news',
    name: 'NewsFeed',
    component: () =>
      import("@/views/NewsArticlesFeedView.vue"),
  },
  {
    path: '/news/:id',
    name: 'NewsArticle',
    component: NewsArticle,
    props: true,
  },
  {
    path: '/events',
    name: 'EventFeed',
    component: () =>
      import("@/views/EventsFeedView.vue"),
  },
  {
    path: '/events/:id',
    name: 'EventArticle',
    component: EventArticle,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !keycloakService.isAuthenticated()) {
    console.log(to.fullPath)
    keycloakService.login(to.fullPath.slice(1, 0))
  } else {
    next();
  }
});


export default router;
