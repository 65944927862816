<template>
  <div id="app">
    <top-bar />
    <navigator-bar />
    <router-view />
    <footer-bar />
  </div>
</template>

<script lang="ts">
import TopBar from "@/components/bars/TopBar.vue";
import NavigatorBar from "@/components/bars/NavigatorBar.vue";
import FooterBar from "@/components/bars/FooterBar.vue";

export default {
  name: "App",
  components: {
    TopBar,
    NavigatorBar,
    FooterBar,
    // ButtonTest,
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/colors.css';

body {
  margin: 0;
  padding: 0;
  background: url("@/assets/Cover.png") center center no-repeat fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 50%;
  background-position-y: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
</style>
