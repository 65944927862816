<template>
    <div class="news">
        <div class="news-content">
            <div class="news-title">
                Vijesti
            </div>
            <div class="news-box">
                <div class="news-list">
                    <div v-for="(articleGroup, groupIndex) in groupedArticles" :key="groupIndex" class="row">
                        <div v-for="article in articleGroup" :key="article.id" class="item nw-block">
                            <div class="item nw-image-container">
                                <DynamicImage :imageUrl="getImgUrl(article.image)" :imageStyle="articleImgStyle" />
                                <div class="overlay">
                                    <router-link :to="`/news/${article.id}`" class="nw-link">
                                        <div class="item nw-title" v-html="article.title"></div>
                                    </router-link>
                                    <div class="item-type">
                                        <div class="circle" v-html="getCircleUnicode(article.type)"></div>
                                        <div class="item nw-type" v-html="article.type"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="news-button">
                <MyButton to="news" type="light" size="large" transparent>Pogledaj sve
                    <!-- @click="navigateToLink" -->
                    vijesti
                </MyButton>
            </div>
        </div>
    </div>
</template>

<script>
import MyButton from "@/components/utility/button/MyButton.vue";
import DynamicImage from "@/components/utility/DynamicImage.vue";

export default {
    name: 'NewsBlock',
    components: {
        MyButton,
        DynamicImage,
    },
    data() {
        return {
            articles: [],
            url: '',
            articleImgStyle: {
            }
        };
    },
    created() {
        this.fetchData();
    },
    computed: {
        groupedArticles() {
            let chunkSize = 4;

            return this.articles.reduce((resultArray, item, index) => {
                const chunkIdex = Math.floor(index / chunkSize);

                if (!resultArray[chunkIdex]) {
                    resultArray[chunkIdex] = [];
                }

                resultArray[chunkIdex].push(item);

                return resultArray;
            }, []);
        }
    },
    methods: {
        async fetchData() {
            try {
                const response = await this.$directusService.fetchArticles("?sort=-date_published&limit=8");
                if (response && response.data) {
                    this.articles = response.data;
                }
                // console.log(this.articles);
                this.url = this.$directusService.fetchUrl();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        getImgUrl(imageId) {
            return `${this.url}/assets/${imageId}`;
        },

        getCircleUnicode(type) {
            switch (type) {
                case 'News':
                    return '&#x1F535;'; // 🔵
                case 'Project':
                    return '&#x1F7E1;'; // 🟡
                case 'Event':
                    return '&#x1F534;'; // 🔴
                case 'Sponsorship':
                    return '&#x1F7E3;'; // 🟣
                case 'Testing':
                    return '&#x1F7E2;'; // 🟢
                default:
                    return '';
            }
        },
    }
}
</script>

<style>
/* Global styles */
a {
    text-decoration: none;
    color: #FFFFFF;
}

a:hover {
    color: #DDDDDD;
}

/* Component-specific styles */
.news {
    font-family: 'Roboto';
    font-style: normal;
    text-align: left;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: 15px;
}

.news-content {
    width: 100%;
    max-width: 1400px;
}

.news-title {
    display: flex;
    padding: 30px 30px 15px 30px;
    line-height: 90%;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    color: #FFC700;
}

.news-box {
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
    gap: 10px;
}

.news-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0px 30px 0px;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.nw-block {
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
}

.nw-image-container {
    position: relative;
    width: 100%;
    padding-top: 60%; /* 16:9 Aspect Ratio (adjust as needed) */
    overflow: hidden;
}

.nw-image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.nw-image-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-end;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.7) 15%, rgba(0, 0, 0, 0.35) 30%, rgba(0, 0, 0, 0) 40%);
}

.nw-title,
.item-type {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 200;
    font-size: 22px;
    line-height: 110%;
    text-align: left;
    color: #FFFFFF;
    padding: 0px 15px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.circle {
    font-size: 12px;
    padding-right: 4px;
}

.item-type {
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-bottom: 6px;
}

.nw-type {
    font-size: 15px;
}

.news-button {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}
</style>
