<template>
    <div class="event-article">
        <div class="event-article-content">
            <div class="event-article-box">
                <div class="test">
                    <div v-if="event">
                        <DynamicImage :imageUrl="getImgUrl(event.image)" :imageStyle="eventImgStyle" />
                        <h1>{{ event.title }}</h1>
                    </div>
                    <div v-else>
                        <p>Loading...</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import directusService from '@/utility/directus/DirectusService';

export default {
    props: ['id'],
    name: 'EventsBlock',
    components: {
    },
    data() {
        return {
            event: null,
            url: '',
        };
    },
    async created() {
        try {
            const response = await directusService.fetchEventById(this.id); // Adjust the method name as per your service
            this.event = response.data;
            this.url = await directusService.fetchUrl();
            console.log(this.url);
        } catch (error) {
            console.error('Error fetching event:', error);
        }
    },
    methods: {
        getImgUrl(imageId) {
            return `${this.url}/assets/${imageId}`;
        },
    }
};
</script>

<style>
.event-article {
    font-family: 'Roboto';
    font-style: normal;
    text-align: left;
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: 15px;
}

.event-article-content {
    width: 100%;
    max-width: 1400px;
}

.events-article-box {
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
    padding: 0px 0px 10px;
    gap: 10px;
    text-align: left;
}
</style>