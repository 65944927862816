<template>
    <div class="sponsors">
        <div class="sponsors-content">
            <div class="sponsors-title">
                Naši partneri
            </div>
            <div class="sponsors-box">
                <Carousel v-bind="settings" :breakpoints="breakpoints" :wrapAround="true">
                    <Slide v-for="sponsor in sponsors" :key="sponsor.id">
                        <div class="carousel__item">
                            <div class="sp-image">
                                <DynamicImage :imageUrl="getImgUrl(sponsor.image)" :imageStyle="eventImgStyle" />
                            </div>
                            <!-- <h2>{{ sponsor.sponsor_name }}</h2> -->
                        </div>
                    </Slide>
                    <template #addons>
                        <Navigation />
                    </template>
                </Carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import DynamicImage from '@/components/utility/DynamicImage.vue'

export default defineComponent({
    name: 'CustomSlides',
    components: {
        Carousel,
        Slide,
        Navigation,
        DynamicImage,
    },
    data: () => ({
        eventImgStyle: {
        },

        sponsors: [],

        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        breakpoints: {
            768: {
                itemsToShow: 3,
                snapAlign: 'start',
            },
            1024: {
                itemsToShow: 5,
                snapAlign: 'start',
            },
        },
        slides: [
        ]
    }),

    created() {
        this.fetchData();
    },

    methods: {
        async fetchData() {
            try {
                const response = await this.$directusService.fetchSponsors("?sort=-date_published");
                // console.log('API response:', response);

                if (Array.isArray(response.data)) {
                    this.sponsors = response.data;
                    this.url = this.$directusService.fetchUrl();
                    // console.log('All Sponsor Names:');
                    // this.sponsors.forEach(sponsor => {
                    //     console.log(sponsor.sponsor_name);
                    // });
                } else {
                    console.error('Invalid response format: Data is not an array');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        getImgUrl(imageId) {
            return String(this.url) + "/assets/" + String(imageId);

        }
    },

    mounted() {
        this.slides.forEach(slide => {
            console.log('Slide Image URL:', slide.imageUrl);
        });
    }
})
</script>

<style scoped>
.sponsors {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--accent-color-2);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
    height: 300px;
    align-items: center;
    justify-content: flex-start;
    padding-inline: 15px;
}

.sponsors-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1400px;
}

.sponsors-title {
    padding: 15px 30px;
    display: flex;
    line-height: 90%;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-weight: 400;
    color: white;
    justify-content: center;
}

.sponsors-box {
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
    height: 200px;
    gap: 8px;
    text-align: left;
    flex-direction: column;
}

.carousel__viewport {
    max-width: 1320px;
    width: 100%;
}

.carousel__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 200px;
    width: 260px;
}

.sp-image {
    width: 100%;
    max-width: 260px;
    height: 100%;
    max-height: 160px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.5);
    background-color: white;
}
</style>