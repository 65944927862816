<template>
    <footer>
        <div class="container">
            <div class="container-content">
                <div class="logo-img">
                    <img class="home-logo1" src="@/assets/mensaLogo.svg">
                </div>
                <div class="container-title">
                    Hrvatska Mensa
                </div>
            </div>
        </div>
        <div class="container-bottom">
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterBar'
}
</script>

<style>
.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--accent-color-1);
    align-items: center;
    justify-content: flex-start;
    padding-inline: 15px;
}

.container-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1400px;
}

.container-title {
    
    padding: 30px;
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    color: white;
    justify-content: start;
}

.logo-img {
    display: flex;
    justify-content: start;
    
}

.home-logo1 {
    padding: 30px 0px 0px 30px;
    height: 200px;
}

.container-bottom {
    background-color: #0B0903;
    height: 80px;
    bottom: 0;
}
</style>
