// src/global-components.ts
import { App } from 'vue';
import MyButton from '@/components/utility/button/MyButton.vue'; 
import DynamicImage from '@/components/utility/DynamicImage.vue';

export default {
  install(app: App) {
    app.component('MyButton', MyButton);
    app.component('DynamicImage', DynamicImage);
  }
};
