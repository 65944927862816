import Keycloak from 'keycloak-js';
// import { toValue } from 'vue';

class KeycloakService {
  private keycloak: Keycloak | undefined;

  constructor() {
    this.keycloak = new Keycloak({
      url: 'https://keycloak-test.mensa.hr',
      // url: 'http://localhost:15000',
      realm: 'mensa-croatia',
      clientId: 'web-site-test',
    });
  }

  init(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.keycloak) {
        reject('Keycloak instance is not defined.');
        return;
      }
      this.keycloak.init({ onLoad: 'check-sso' })
        .then(authenticated => {
          resolve(authenticated);
        }).catch(error => {
          reject(error);
        });
    });
  }

  isAuthenticated(): boolean {
    return !!this.keycloak?.authenticated;
  }

  login(redirectUri?: string) {
    this.keycloak?.login({ redirectUri });
  }

  logout() {
    this.keycloak?.logout();
  }

  getToken(): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      if (this.keycloak?.token) {
        this.keycloak.updateToken(5)
          .then(() => {
            resolve(this.keycloak?.token);
          })
          .catch(() => {
            reject('Failed to refresh token');
          });
      } else {
        reject('Not logged in');
      }
    });
  }

  getTokenParsed() {
    return this.keycloak?.tokenParsed;
  }
}

const keycloakService = new KeycloakService();
export default keycloakService;

