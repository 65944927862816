<template>
  <div class="dynamic-image">
    <img :src="imageSrc" :style="imageStyle" alt="Dynamic Image" />
  </div>
</template>

<script>
export default {
  name: 'dynamic-image',
  props: {
    imageUrl: {
      type: String,
      default: undefined,
    },
    imageStyle: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    imageSrc() {
      // Check if imageUrl ends with "/undefined"
      if (this.imageUrl && this.imageUrl.endsWith('/undefined')) {
        // If it ends with "/undefined", return the blank image
        return this.blankImage;
      } else {
        // Otherwise, return the provided imageUrl
        return this.imageUrl;
      }
    }
  },
  data() {
    return {
      blankImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAEsAQAAAABRBrPYAAAAEklEQVR42mP8z8AARIYGKgAD2ABo8r9mAAAAAElFTkSuQmCC' // Placeholder blank image data URI
    };
  }
}
</script>

<style scoped>
.dynamic-image {
  /* Ensure the image fills its parent container */
  width: 100%;
  height: 100%;
  /* Hide any parts of the image that overflow the container */
  overflow: hidden;
}

/* Style the image */
.dynamic-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensure the image covers the container without distortion */
}
</style>