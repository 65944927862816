<template>
    <MyButton v-if="!isAuthenticated" type="light" size="medium" transparent @customClick="login">
        Login
    </MyButton>
    <MyButton v-if="isAuthenticated" type="light" size="medium" transparent @customClick="logout">
        Logout
    </MyButton>
</template>

<script lang="ts">
import keycloakService from '@/utility/auth/KeycloakService';
import { defineComponent } from 'vue';
import MyButton from './utility/button/MyButton.vue';

export default defineComponent({
    name: 'MemberLogin',

    computed: {
        isAuthenticated() {
            return keycloakService.isAuthenticated();
        }
    },
    
    methods: {
        async login() {
            console.log('login method called');
            await keycloakService.login();
        },
        async logout() {
            console.log('logout method called');
            await keycloakService.logout();
        }
    }
});
</script>