<template>
  <div :class="{ 'sticky-header': true, 'scrolled': yOffset > 41 }">
    <nav class="navigator-menu">
      <div class="logo-container">
        <div class="navigator-logo">
          <router-link to="/">
            <img :class="{ 'home-logo': true, 'scrolled': yOffset > 41 }" src="@/assets/mensaLogo.svg">
          </router-link>
        </div>
      </div>
      <div class="links-container">
        <div class="navigator-links">
          <div class="button">
            <button onclick="window.location='http://localhost:8080/omensi';" class="dropdown-btn">O MENSI
              &#9662;</button>
            <div class="dropdown-box">
              <div class="dropdown-btn-content">
                <a href="about-mensa">Mensa</a>
                <a href="branches">Podružnice</a>
                <a href="officials">Dužnosnici</a>
                <a href="statute">Statut</a>
                <a href="sponsors">Prijatelji HM</a>
                <a href="mensa-intl">Mensa international</a>
              </div>
            </div>
          </div>
          <div class="button">
            <button onclick="window.location='http://localhost:8080/testing';" class="dropdown-btn">TESTIRANJE</button>
          </div>
          <div class="button">
            <button onclick="window.location='http://localhost:8080/projects';" class="dropdown-btn">PROJEKTI
              &#9662;</button>
            <div class="dropdown-box">
              <div class="dropdown-btn-content">
                <a href="sudoku">Sudoku projekt</a>
                <a href="elq">ELQ liga</a>
                <a href="blood-donation">Darivanje krvi</a>
              </div>
            </div>
          </div>
          <button class="normal-btn">KONTAKT</button>
          <button class="normal-btn">WEBSHOP</button>
          <!-- <button v-if="isAuthenticated" class="normal-btn" >PROFIL</button> -->
          <div v-if="isAuthenticated" class="normal-btn">
            <router-link to="/profile">PROFIL</router-link>
          </div>
          <div class="nav-menu-item">
            <MemberLogin />
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import keycloakService from '@/utility/auth/KeycloakService';
import MemberLogin from '@/components/MemberLogin.vue';
import '@/assets/styles/colors.css';

export default {
  components: {
    MemberLogin,
  },

  computed: {
    isAuthenticated() {
      return keycloakService.isAuthenticated();
    }
  },

  data: () => {
    return {
      isVisible: false,
      yOffset: 0
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll); // Add scroll event listener
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll); // Remove scroll event listener when component is destroyed
  },

  methods: {
    showDropdown() {
      this.isVisible = true;
    },

    hideDropdown() {
      this.isVisible = false;
    },
    handleScroll() {
      // Update yOffset on scroll
      this.yOffset = window.scrollY || window.pageYOffset;
      // You can perform other actions based on yOffset value here
    },
  },
  name: 'NavBar'
}
</script>

<style>
nav {
  a {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 50;
    text-decoration: none;
    color: var(--white);

    &.router-link-exact-active {
      color: var(--primary-color);
    }
  }
}

.sticky-header {
  display: flex;
  position: sticky;
  height: 80px;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  justify-content: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

}

.sticky-header.scrolled {
  background-color: var(--accent-color-1);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}

.navigator-menu {
  display: flex;
  justify-content: center;
  max-width: 1600px;
  width: 100%;
  padding: 0px 30px;
}

/* links */

.links-container {
  display: flex;
  flex: 0 0 75%;
  max-width: 75%;
  align-items: center;
  justify-content: end;
}

.navigator-links {
  display: flex;
  min-width: 75%;
  gap: 30px;
  justify-self: end;
  justify-content: end;
}

.dropdown-btn {
  background-color: transparent;
  color: var(--white);
  font-size: 20px;
  border: none;
}

.button {
  align-content: center;
}

.normal-btn {
  background-color: transparent;
  color: var(--white);
  font-size: 20px;
  border: none;
}

.dropdown-btn-content {
  margin-top: 30px;
  margin-bottom: 40px;
  margin-inline: 20px;
}

.dropdown-box {
  position: absolute;
  background-color: var(--accent-color-3);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 15px;
  flex-direction: column;
  justify-self: flex-start;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  margin-top: 3px;

  display: none;
  
}

.button:hover .dropdown-box {
  display: block;
  opacity: 1;
}

.dropdown-box a {
  color: var(--accent-color-1);
  font-size: 20px;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #c9c9c9;
  margin-top: 20px;
}

.dropdown-box a:hover {
  color: var(--accent-color-2);
}

/* logo */

.logo-container {
  display: flex;
  flex: 0 0 25%;
  max-width: 25%;
  height: 80px;
  align-items: center;
}

.home-logo {
  display: flex;
  min-width: 25%;
  justify-self: start;
  transition: all 0.2s ease;
}

.home-logo.scrolled {
  transform: scale(0.8);
}
</style>